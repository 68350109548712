import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="prose mx-auto text-prose-xl">
      <h1>{`Late Summer Day`}</h1>
      <p>{`2017`}</p>
      <p>{`18x24in`}</p>
      <p>{`Acrylic on canvas`}</p>
      <p>{`Available: contact for price.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/cec813481b8415294fc7d3b6c58744d8/9568a/IMG-1083.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAe+hhgUUnzUmpgGv/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERAhASMf/aAAgBAQABBQLKIalQxw7CNe8M/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERIRAxQf/aAAgBAQABPyHGQ7aN4cdCUYtcY+aMg2Jsd0//2gAMAwEAAgADAAAAEP8AN0D/xAAYEQEAAwEAAAAAAAAAAAAAAAAAARExIf/aAAgBAwEBPxDIdRqn/8QAFxEBAAMAAAAAAAAAAAAAAAAAABAhQf/aAAgBAgEBPxBTI//EABwQAAMAAwEBAQAAAAAAAAAAAAABESExQVGRof/aAAgBAQABPxBCaFa+DRcfBGVWOHqoZ7KQ3ksMCSFcC2jabxRrbg7dyNNK88Gt/wAP/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1083",
              "title": "IMG-1083",
              "src": "/static/cec813481b8415294fc7d3b6c58744d8/4b190/IMG-1083.jpg",
              "srcSet": ["/static/cec813481b8415294fc7d3b6c58744d8/e07e9/IMG-1083.jpg 200w", "/static/cec813481b8415294fc7d3b6c58744d8/066f9/IMG-1083.jpg 400w", "/static/cec813481b8415294fc7d3b6c58744d8/4b190/IMG-1083.jpg 800w", "/static/cec813481b8415294fc7d3b6c58744d8/e5166/IMG-1083.jpg 1200w", "/static/cec813481b8415294fc7d3b6c58744d8/b17f8/IMG-1083.jpg 1600w", "/static/cec813481b8415294fc7d3b6c58744d8/9568a/IMG-1083.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b8d748094fc600d531dfec964f5a631c/9568a/IMG-1084.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAEDAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAH0HnWSIhGc7ipItf/EABgQAQEBAQEAAAAAAAAAAAAAAAEAERAx/9oACAEBAAEFAgsnN4+7xZbZv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABgRAAIDAAAAAAAAAAAAAAAAAAAQESFB/9oACAECAQE/AdKIX//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABoQAAMBAAMAAAAAAAAAAAAAAAABIRARMVH/2gAIAQEAAT8hhh09HI3MpjgnMGCZnY//2gAMAwEAAgADAAAAEGPKzf/EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQMBAT8Q4WsLtl//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ELZMDzgnFD//xAAdEAEBAQEAAwADAAAAAAAAAAABEQAhMVFhQXGB/9oACAEBAAE/EDGQOaDzBnPWkI8ecMsLzI0/yesf05mSgg/Gf5+aiVFmIW9uY63Jz//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1084",
              "title": "IMG-1084",
              "src": "/static/b8d748094fc600d531dfec964f5a631c/4b190/IMG-1084.jpg",
              "srcSet": ["/static/b8d748094fc600d531dfec964f5a631c/e07e9/IMG-1084.jpg 200w", "/static/b8d748094fc600d531dfec964f5a631c/066f9/IMG-1084.jpg 400w", "/static/b8d748094fc600d531dfec964f5a631c/4b190/IMG-1084.jpg 800w", "/static/b8d748094fc600d531dfec964f5a631c/e5166/IMG-1084.jpg 1200w", "/static/b8d748094fc600d531dfec964f5a631c/b17f8/IMG-1084.jpg 1600w", "/static/b8d748094fc600d531dfec964f5a631c/9568a/IMG-1084.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3dc3deaaae5fd425af35358a124ad6dd/9568a/IMG-1085.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAeg4YxCEVObqyTF//8QAGhABAQACAwAAAAAAAAAAAAAAAAECESAhMf/aAAgBAQABBQLFp0i16lb4/wD/xAAWEQADAAAAAAAAAAAAAAAAAAAAECH/2gAIAQMBAT8BK//EABgRAAIDAAAAAAAAAAAAAAAAAAAQESFB/9oACAECAQE/AdKIX//EABYQAQEBAAAAAAAAAAAAAAAAACAxYf/aAAgBAQAGPwIYKP/EABsQAQEBAAMBAQAAAAAAAAAAAAEAETFRcRAh/9oACAEBAAE/IQZ6QTmcPds0LPFnY4sDH8ieZn7/AP/aAAwDAQACAAMAAAAQCxb/AP/EABgRAQADAQAAAAAAAAAAAAAAAAABESFB/9oACAEDAQE/EOLInVv/xAAYEQADAQEAAAAAAAAAAAAAAAAAARExcf/aAAgBAgEBPxC5IpM6JNKH/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITFBUWFxkbH/2gAIAQEAAT8QUSyCI52UJHURXLCosGSIWh+GLo5ae5XrerxCcLV+ZeCwdARtLzDZ6i1qvk//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1085",
              "title": "IMG-1085",
              "src": "/static/3dc3deaaae5fd425af35358a124ad6dd/4b190/IMG-1085.jpg",
              "srcSet": ["/static/3dc3deaaae5fd425af35358a124ad6dd/e07e9/IMG-1085.jpg 200w", "/static/3dc3deaaae5fd425af35358a124ad6dd/066f9/IMG-1085.jpg 400w", "/static/3dc3deaaae5fd425af35358a124ad6dd/4b190/IMG-1085.jpg 800w", "/static/3dc3deaaae5fd425af35358a124ad6dd/e5166/IMG-1085.jpg 1200w", "/static/3dc3deaaae5fd425af35358a124ad6dd/b17f8/IMG-1085.jpg 1600w", "/static/3dc3deaaae5fd425af35358a124ad6dd/9568a/IMG-1085.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/47b68e6603f5c6d913a96b3ca1e1cd45/9568a/IMG-1086.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABrZ6YpnSNBtgilMH/xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAxIhMf/aAAgBAQABBQIhZfazx+OjC/YV2jcTHZUsa3abmf/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEr/8QAFREBAQAAAAAAAAAAAAAAAAAAIEH/2gAIAQIBAT8Bo//EABwQAAEEAwEAAAAAAAAAAAAAAAABEBExAiFRcf/aAAgBAQAGPwKeGsSxIJWm8IxNrDW3/8QAHRAAAgIDAAMAAAAAAAAAAAAAAAERITFBYaGx0f/aAAgBAQABPyGNJVatilYdSQ7I6p9oSwIb0TklQyfsNLa9IeflYgVoyj4FDnwf/9oADAMBAAIAAwAAABDMCr//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAEDAQE/EIy6sCPP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxAVEUNR/8QAHxABAAICAgIDAAAAAAAAAAAAAREhADFRYXGxQZHw/9oACAEBAAE/EFB0yyIeb8xhS0GgSsV6vFCAuAIPHGFiIkYbNbP28CohZEi667ykZKytnOUJbRdurxoYPAwGwMmnSoho+sVMDPBkQiFRphQerP/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1086",
              "title": "IMG-1086",
              "src": "/static/47b68e6603f5c6d913a96b3ca1e1cd45/4b190/IMG-1086.jpg",
              "srcSet": ["/static/47b68e6603f5c6d913a96b3ca1e1cd45/e07e9/IMG-1086.jpg 200w", "/static/47b68e6603f5c6d913a96b3ca1e1cd45/066f9/IMG-1086.jpg 400w", "/static/47b68e6603f5c6d913a96b3ca1e1cd45/4b190/IMG-1086.jpg 800w", "/static/47b68e6603f5c6d913a96b3ca1e1cd45/e5166/IMG-1086.jpg 1200w", "/static/47b68e6603f5c6d913a96b3ca1e1cd45/b17f8/IMG-1086.jpg 1600w", "/static/47b68e6603f5c6d913a96b3ca1e1cd45/9568a/IMG-1086.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ec7152f8c99cef451faa11a25be2df2d/9568a/IMG-1093.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAQL/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHV5Oec66uwrBbsQ//EABwQAAICAgMAAAAAAAAAAAAAAAERAAISQQMhMf/aAAgBAQABBQKzbUy5JvLs2hDJKGRW/JYkz//EABcRAQEBAQAAAAAAAAAAAAAAAAARARL/2gAIAQMBAT8BVzqP/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAh/9oACAECAQE/AXT/xAAbEAACAgMBAAAAAAAAAAAAAAAAARARAiExkf/aAAgBAQAGPwLsaT9jhrGx0xqhWZGo/8QAHRABAAIDAQADAAAAAAAAAAAAAQARITFBUWFxgf/aAAgBAQABPyHBMC8zYq++4iu0chu5tvV7mWH2Ri+L2ohKQJuDwPiDptXYL+kVN7XHC3k//9oADAMBAAIAAwAAABAo07//xAAZEQEBAQADAAAAAAAAAAAAAAABABExQVH/2gAIAQMBAT8Qd5heWDvUBv/EABkRAQEBAAMAAAAAAAAAAAAAAAEAESHw8f/aAAgBAgEBPxANcuO+wiWi/8QAIBABAAIDAAEFAQAAAAAAAAAAAREhADFBYVFxgbHh8P/aAAgBAQABPxCADumZXwZSOV56en9vFJ6UuUxt4yYFhtv2wRAiXoKyUM0DJZXQCeXys1wD0B15rBpJ2tO8FAx+cUsgTLf3jEq/Drn/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1093",
              "title": "IMG-1093",
              "src": "/static/ec7152f8c99cef451faa11a25be2df2d/4b190/IMG-1093.jpg",
              "srcSet": ["/static/ec7152f8c99cef451faa11a25be2df2d/e07e9/IMG-1093.jpg 200w", "/static/ec7152f8c99cef451faa11a25be2df2d/066f9/IMG-1093.jpg 400w", "/static/ec7152f8c99cef451faa11a25be2df2d/4b190/IMG-1093.jpg 800w", "/static/ec7152f8c99cef451faa11a25be2df2d/e5166/IMG-1093.jpg 1200w", "/static/ec7152f8c99cef451faa11a25be2df2d/b17f8/IMG-1093.jpg 1600w", "/static/ec7152f8c99cef451faa11a25be2df2d/9568a/IMG-1093.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d2235fed7235744e73cf7db28c9d7ce0/a0f4c/IMG-1094.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "146.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAegpkrLjBNalY6yQqD//xAAaEAADAQEBAQAAAAAAAAAAAAAAAQIREiED/9oACAEBAAEFAjsWNNZGnRT8qtFUk/TV4Yj/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BWZP/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREQIjFB/9oACAEBAAY/AqxlSHmnhsRo4jh//8QAGxAAAgMBAQEAAAAAAAAAAAAAAREAITFBYVH/2gAIAQEAAT8hri+ARGSowgqE4AHJi0vYAFMS/wBLY1TVTEXZWfkqjACQ08hfif/aAAwDAQACAAMAAAAQo8s9/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAwEBPxBh5DyQu2X/xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhgf/aAAgBAgEBPxAM24UuX//EABwQAQADAQEAAwAAAAAAAAAAAAEAESExUUFxof/aAAgBAQABPxBoqMlo5EVsoVcQMl5JmKRCih4O5cCfAa0gqsF4ZFK6ei/yKYiMGr6bjlaa84Hkpkx5vI4RFoRx1mE//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1094",
              "title": "IMG-1094",
              "src": "/static/d2235fed7235744e73cf7db28c9d7ce0/4b190/IMG-1094.jpg",
              "srcSet": ["/static/d2235fed7235744e73cf7db28c9d7ce0/e07e9/IMG-1094.jpg 200w", "/static/d2235fed7235744e73cf7db28c9d7ce0/066f9/IMG-1094.jpg 400w", "/static/d2235fed7235744e73cf7db28c9d7ce0/4b190/IMG-1094.jpg 800w", "/static/d2235fed7235744e73cf7db28c9d7ce0/e5166/IMG-1094.jpg 1200w", "/static/d2235fed7235744e73cf7db28c9d7ce0/b17f8/IMG-1094.jpg 1600w", "/static/d2235fed7235744e73cf7db28c9d7ce0/a0f4c/IMG-1094.jpg 2368w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fc9b0816a1d6873c38667836ea0855e5/9568a/IMG-1098.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgH/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAacr4SorKyp86x5MYv/EABwQAAIBBQEAAAAAAAAAAAAAAAECAAMREiEiQv/aAAgBAQABBQL1fm1WO3NPULNGTMk6yNvCyod//8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERECH/2gAIAQMBAT8BnKUTef/EABcRAQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQIBAT8Bimv/xAAdEAABBAIDAAAAAAAAAAAAAAAAARARIQKhMWFx/9oACAEBAAY/AuitNDVjo8OBJFf/xAAbEAEBAQADAQEAAAAAAAAAAAABEQAhMUFhUf/aAAgBAQABPyFCGoCwwS6XvPkaVOXF7NPnGq7j9cuq3p+XRZ48+YdIs9wxPrm8ZJ583//aAAwDAQACAAMAAAAQuPcw/8QAGREBAAMBAQAAAAAAAAAAAAAAAQARITFB/9oACAEDAQE/EM3Sz4S4LwgDtT//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EA1y5IyUcv/EACAQAQEAAgICAgMAAAAAAAAAAAERACFBUTFhcYGxweH/2gAIAQEAAT8QIlirddq9ZJIize2/wc6hOBTxhMKoiD4nP41lNACqkGr84wNkHlP3kTwqunY1gPXQCKHpzrKsnTtfLjCMf2YarsVLvGqr7+3P/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1098",
              "title": "IMG-1098",
              "src": "/static/fc9b0816a1d6873c38667836ea0855e5/4b190/IMG-1098.jpg",
              "srcSet": ["/static/fc9b0816a1d6873c38667836ea0855e5/e07e9/IMG-1098.jpg 200w", "/static/fc9b0816a1d6873c38667836ea0855e5/066f9/IMG-1098.jpg 400w", "/static/fc9b0816a1d6873c38667836ea0855e5/4b190/IMG-1098.jpg 800w", "/static/fc9b0816a1d6873c38667836ea0855e5/e5166/IMG-1098.jpg 1200w", "/static/fc9b0816a1d6873c38667836ea0855e5/b17f8/IMG-1098.jpg 1600w", "/static/fc9b0816a1d6873c38667836ea0855e5/9568a/IMG-1098.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "800px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/ae2c8bcc69769a4b6b093657a5027921/9568a/IMG-1666.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "133.5%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgT/xAAXAQEBAQEAAAAAAAAAAAAAAAACAQAD/9oADAMBAAIQAxAAAAHmab4WJimJ3kdc0R//xAAbEAEAAgIDAAAAAAAAAAAAAAABABECEiEiQv/aAAgBAQABBQJoYBLm1u0Lj2TLjyFs/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAEDAQE/AXS//8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AXT/xAAbEAABBAMAAAAAAAAAAAAAAAAQAAERIQIgYf/aAAgBAQAGPwKXUaUuCxiP/8QAHRABAAICAwEBAAAAAAAAAAAAAQARITFBUYFxwf/aAAgBAQABPyHYGsBNFrE3UAYm99Rya97leF+SkUBTwS1R9qND8wlu4YJ//9oADAMBAAIAAwAAABC02cL/xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QiDE6yz//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAQ/9oACAECAQE/EI3CTJC8/wD/xAAeEAEBAAIBBQEAAAAAAAAAAAABEQAhMUFRYXGBkf/aAAgBAQABPxBUykUEu/vGBAlh9h+9clSgZw79zLKcfO+WeMtDKzUKlKonecyMGSD4vHu4AaEapGeXt5w8VekQJ0mKgmosAdURRS4AIH5n/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "IMG-1666",
              "title": "IMG-1666",
              "src": "/static/ae2c8bcc69769a4b6b093657a5027921/4b190/IMG-1666.jpg",
              "srcSet": ["/static/ae2c8bcc69769a4b6b093657a5027921/e07e9/IMG-1666.jpg 200w", "/static/ae2c8bcc69769a4b6b093657a5027921/066f9/IMG-1666.jpg 400w", "/static/ae2c8bcc69769a4b6b093657a5027921/4b190/IMG-1666.jpg 800w", "/static/ae2c8bcc69769a4b6b093657a5027921/e5166/IMG-1666.jpg 1200w", "/static/ae2c8bcc69769a4b6b093657a5027921/b17f8/IMG-1666.jpg 1600w", "/static/ae2c8bcc69769a4b6b093657a5027921/9568a/IMG-1666.jpg 3024w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      